import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Gif = makeShortcode("Gif");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "why-add-keyboard-focus"
    }}>{`Why Add Keyboard Focus`}</h2>
    <p>{`Users navigating an interface using a keyboard need ways to validate their shift from one section of
a page to another. Adding the keyboard focus such as in the button example shown is a way these
users can quickly and easily validate their navigation status.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "424px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cfa668747f7f079016773ad1bc9a2e58/1cfa9/outline-button-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.83783783783784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Button Before Focus",
            "title": "Button Before Focus",
            "src": "/static/cfa668747f7f079016773ad1bc9a2e58/1cfa9/outline-button-1.png",
            "srcSet": ["/static/cfa668747f7f079016773ad1bc9a2e58/1efb2/outline-button-1.png 370w", "/static/cfa668747f7f079016773ad1bc9a2e58/1cfa9/outline-button-1.png 424w"],
            "sizes": "(max-width: 424px) 100vw, 424px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span><em parentName="p">{`Large Primary Outline button before
receiving keyboard focus`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "424px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8b179a438a22ced49283cee03585f1a7/1cfa9/outline-button-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.83783783783784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Button After Focus",
            "title": "Button After Focus",
            "src": "/static/8b179a438a22ced49283cee03585f1a7/1cfa9/outline-button-2.png",
            "srcSet": ["/static/8b179a438a22ced49283cee03585f1a7/1efb2/outline-button-2.png 370w", "/static/8b179a438a22ced49283cee03585f1a7/1cfa9/outline-button-2.png 424w"],
            "sizes": "(max-width: 424px) 100vw, 424px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span><em parentName="p">{`Large Primary Outline button after
receiving keyboard focus`}</em></p>
    <p>{`Just like with a mouse, users interact with one object at a time. When a user interacts with an
object, that object is said to receive focus, and focused elements should have visible focus
indicators that inform users where keyboard focus is on the webpage.`}</p>
    <Gif mdxType="Gif">
      <p><img parentName="p" {...{
          "src": "/9de5166ad7a64787f967367a458a3218/focus.gif",
          "alt": "How a user keyboard-navigates through each element in the Workday homepage"
        }}></img>{`
`}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4deb9286624e045d3548645525475c5e/7321b/focus.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "73.24324324324326%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC4UlEQVR42o1UW0/UQBTeH+SLXPQv6YOXZxNJ0KBCjCY+iIjcEtCYGB9QCZcVUIMaiahABNktZZddoN12204vM5129/PM7IvxQXn4MmfOTL9z+c60kPAIecbRbucAWoT89GhnSFOOPLHALAPW8REKZ69toOv6N3T1fUd33ybO9f/E+RuE/h303iyhZ6CM3gFDo+f2AXoGq+i+c4juwRp679bRPXSEnqETnLlVx4OFQxTuvzIwuriHJ0ValwwMv/mF0YUyRuZLGJ438Lh4oPFwgc4WTTxaqmCkWMHDJfItmRh7b2P2R4ALz11cmibCETqYWS1jamUfE29LGC927KkVE5PLJkaLisTE9PsqJt8dakysVjC+WsXYcgXTnxqY2/Jw+YWDq8+qKMxu2PhccrBpOjAaQqNMKNkCNV/iNUUfmrOxZ8X4smfha9lGqZGi7Ei6l2HnRMBiKe6tBLiiCLcrHljEkCYM7VaKdi7QUsgENT7Fdi3C5JpD/gSu5yGiu20Sr9XqiJjptY2Z9RgXpyoo1KwmvKYNx3XQcBpgLCDlBBLO6Z7Ahskw+cFFwCVWSyERSewex9ikQIrw5XaM/abEdp3j6RqpHBKBZZ/AcWyNJIk0oRAcmeTwIg6Dyg15ipobQcgUjs/gsUiPzkHdguV6ZGdo0veFOA4pK18jCDwN329C+YVIkOcppBSIhNDkijBOJRgFUBm6YUL7FFkmcXRMhKnsbBSUzeIEMuv48lyCE5Faa57A8q6v+7VVY1g/YNpe3PFQaSbEnRGhhYIfhPgTwV97n8qTUkLQiwjCUGcleALOY203XRtxFCDLMzQaLgoB9YL9AypARhkzEsVmXPetGUvUA6kJTwIKROWrKlgY/Z9QZdkmZQ07xrrpa5KtY441M9Ylfyz7MJ1Ev2t191QZqt7mNJsyjekn0tIB9MyqORQxzS0JRz0PWHj6kqM008KoDA2H02vp2FUSxKd2tKhkleFvEq9I4JapkzsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "How a user keyboard-navigates through each element in the Workday homepage",
              "title": "How a user keyboard-navigates through each element in the Workday homepage",
              "src": "/static/4deb9286624e045d3548645525475c5e/50383/focus.png",
              "srcSet": ["/static/4deb9286624e045d3548645525475c5e/1efb2/focus.png 370w", "/static/4deb9286624e045d3548645525475c5e/50383/focus.png 740w", "/static/4deb9286624e045d3548645525475c5e/7321b/focus.png 1184w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gif>
    <p><em parentName="p">{`How a user keyboard-navigates through each element in the Workday homepage`}</em></p>
    <p>{`It is up to web developers and content creators to ensure that interactive elements are easily
navigated by keyboard and that focus indicators have enough contrast on a page. Intuitive, logical,
and consistent focus management ensures users can easily navigate by keyboard throughout your UI.
Alternatively, ignoring focus management results in redundant, restrictive, or nonexistent
navigation. Websites without keyboard support present a barrier to people with disabilities.`}</p>
    <h2 {...{
      "id": "create-excellent-navigation-experiences"
    }}>{`Create Excellent Navigation Experiences`}</h2>
    <p>{`Keyboard focus should visibly follow the shortest possible path while allowing the user to reach all
functionality. Achieve this by ensuring that:`}</p>
    <ul>
      <li parentName="ul">{`Focus moves from where a user starts on a page to where the user ends on a page (left to right,
top to bottom).`}</li>
      <li parentName="ul">{`All keyboard-navigable elements have distinct focus indicators that follow
`}<a parentName="li" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`color contrast requirements`}</a>{`.`}</li>
      <li parentName="ul">{`Focus doesn’t move away from components if users didn’t initiate the movement.`}</li>
      <li parentName="ul">{`Focus moves logically when a control is removed, usually to the closest actionable element.`}</li>
      <li parentName="ul">{`When keyboard focus moves, the movement appears visually through a focus indicator.`}</li>
      <li parentName="ul">{`When components that restrict focus (including dialogs, menus, popups, and lists) are closed,
focus returns to their triggering elements.`}</li>
      <li parentName="ul">{`Components such as tab panels, menus, and lists utilize
`}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.1/"
        }}>{`standard keyboard navigation patterns`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "on-page-load"
    }}>{`On Page Load`}</h2>
    <p>{`The integration of interaction patterns when a page loads critically affects user experience for
keyboard navigation users. Keyboard-navigation users expect that, on page load, the keyboard focus
is:`}</p>
    <ul>
      <li parentName="ul">{`Programmatically set prior to the page loading.`}</li>
      <li parentName="ul">{`Placed logically in a consistent manner (depending on the scenario).`}</li>
    </ul>
    <h2 {...{
      "id": "errors"
    }}>{`Errors`}</h2>
    <p>{`Errors can disrupt user flow for any user, but they can be particularly disruptive for
keyboard-navigation users. Blocks of content between the error and submission areas can prevent
keyboard navigation users from efficiently submitting a form. Create more efficient form validation
by ensuring that:`}</p>
    <ul>
      <li parentName="ul">{`After a user submits a form and receives an error notification, focus moves to the first form
field that has an error.`}</li>
      <li parentName="ul">{`Key areas of the form, such as the submission button, are always keyboard-navigable.`}</li>
    </ul>
    <h2 {...{
      "id": "dos-and-donts"
    }}>{`Do's and Don'ts`}</h2>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Use distinct focus indicators that meet color contrast requirements.`}</li>
        <li parentName="ul">{`Consider navigation edge cases.`}</li>
        <li parentName="ul">{`Create logical navigation experiences, referencing standard navigation patterns.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Move focus without user input.`}</li>
        <li parentName="ul">{`Create new navigation patterns when standard patterns suffice.`}</li>
      </ul>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      